var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { PrincipalService } from "~/services/manage-service/principal.service";
import { Emit } from "vue-property-decorator";
import { Getter } from "vuex-class";
var AddPrincipal = /** @class */ (function (_super) {
    __extends(AddPrincipal, _super);
    function AddPrincipal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.submitLoading = false;
        // 公司数据实体
        _this.principalModel = {
            phone: "",
            name: "",
            remark: "",
        };
        _this.principalRules = {
            name: [
                { required: true, message: "请输入委托方名称", trigger: "blur" }
            ],
            type: [{ required: true, message: "请选择状态", trigger: "change" }]
        };
        return _this;
    }
    AddPrincipal.prototype.refreshList = function () { };
    AddPrincipal.prototype.close = function () { };
    AddPrincipal.prototype.commit = function () {
        var _this = this;
        var form = this.$refs.form;
        form.validate(function (valid) {
            if (!valid) {
                return;
            }
            _this.submitLoading = true;
            _this.principalService.addPrincipal(_this.principalModel).subscribe(function (data) {
                _this.$message.success("新增成功!");
                _this.submitLoading = false;
                _this.refreshList();
                _this.close();
            }, function (_a) {
                var msg = _a.msg;
                _this.submitLoading = false;
            });
        });
    };
    AddPrincipal.prototype.refresh = function () {
        var form = this.$refs.form;
        form.resetFields();
    };
    __decorate([
        Dependencies(PrincipalService)
    ], AddPrincipal.prototype, "principalService", void 0);
    __decorate([
        Emit("refreshList")
    ], AddPrincipal.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], AddPrincipal.prototype, "close", null);
    __decorate([
        Getter
    ], AddPrincipal.prototype, "isSupperAdmin", void 0);
    AddPrincipal = __decorate([
        Component({
            components: {}
        })
    ], AddPrincipal);
    return AddPrincipal;
}(Vue));
export default AddPrincipal;
