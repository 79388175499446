var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import AddPrincipal from "~/components/system-manage/principal-manage/add-principal.vue";
import ModifyPrincipal from "~/components/system-manage/principal-manage/modify-principal.vue";
import { Action, State, Getter } from "vuex-class";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Layout, Dependencies } from "~/core/decorator";
import { PrincipalService } from "~/services/manage-service/principal.service";
import EntrustPowerManage from "~/components/system-manage/principal-manage/entrust-power-manage.vue";
// @Auth(1112)
var PrincipalManagement = /** @class */ (function (_super) {
    __extends(PrincipalManagement, _super);
    function PrincipalManagement() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.dialog = {
            addprincipal: false,
            modifyprincipal: false,
            module: false,
        };
        // 电调表单验证规则
        _this.callRule = {
        // phone: [{
        //   message: "请输入正确的电话号码",
        //   trigger: "blur",
        //   pattern: /^1[3456789]\d{9}$/
        // }]
        };
        // form 查询表单参数
        _this.queryModel = {
            code: "",
            phone: "",
            status: "",
        };
        _this.principalObj = {};
        _this.queryprincipalDataSet = [];
        return _this;
    }
    /**
     * 考虑到非管理员不显示公司选择控件
     * get 操作的时候强制设置非管理员所在公司码
     */
    // get principalCode() {
    // if (!this.isSupperAdmin) {
    //   this.queryModel.code = this.userData.principalCode;
    // }
    // return this.queryModel.code;
    // }
    // set principalCode(value) {
    // this.queryModel.code = value;
    // }
    /**
     * 页面激活
     */
    PrincipalManagement.prototype.activated = function () {
        if (!this.isSupperAdmin) {
            this.refreshData();
        }
    };
    PrincipalManagement.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    PrincipalManagement.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 查询公司
     */
    PrincipalManagement.prototype.refreshData = function () {
        var _this = this;
        // this.loading.state = true;
        this.principalService.findAllPrincipal(this.queryModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.queryprincipalDataSet = data;
        });
    };
    /**
     * 修改公司信息
     */
    PrincipalManagement.prototype.modifyprincipal = function (scope) { };
    PrincipalManagement.prototype.createprincipalClick = function () {
        this.dialog.addprincipal = true;
    };
    /**
     * 启用或停用委托方
     */
    PrincipalManagement.prototype.updatePrincipalStateClick = function (scope, status) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u8BA4\u8981" + (scope.row.status === "ON" ? "禁用" : "启用") + "\u8BE5\u59D4\u6258\u65B9\u5417?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(function () {
            _this.principalService.modifyPrincipal({ id: scope.row.id, name: scope.row.name, status: status }).subscribe(function (data) {
                _this.$message({
                    type: "success",
                    message: "操作成功!",
                });
                _this.pageService.reset();
                _this.refreshData();
                // 刷新委托方列表
                _this.getPrincipalList();
            }, function (_a) {
                var msg = _a.msg;
                _this.refreshData();
            });
        });
    };
    PrincipalManagement.prototype.powerManage = function () {
        this.dialog.module = true;
    };
    __decorate([
        Dependencies(PageService)
    ], PrincipalManagement.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], PrincipalManagement.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PrincipalService)
    ], PrincipalManagement.prototype, "principalService", void 0);
    __decorate([
        Getter
    ], PrincipalManagement.prototype, "isSupperAdmin", void 0);
    __decorate([
        State
    ], PrincipalManagement.prototype, "userData", void 0);
    __decorate([
        Action
    ], PrincipalManagement.prototype, "getPrincipalList", void 0);
    PrincipalManagement = __decorate([
        Layout("workspace"),
        Component({
            components: {
                ModifyPrincipal: ModifyPrincipal,
                DataForm: DataForm,
                DataBox: DataBox,
                AddPrincipal: AddPrincipal,
                EntrustPowerManage: EntrustPowerManage,
            },
        })
    ], PrincipalManagement);
    return PrincipalManagement;
}(Vue));
export default PrincipalManagement;
